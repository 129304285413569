import { useForm } from 'react-hook-form'
import styles from './ReferralForm.module.scss'
import { useTranslation } from 'next-i18next'
import { Email } from 'constants/regex'
import classNames from 'classnames'
import { Button } from '@boltenergy-be/design-system'
import { getReferralCodeByEmail } from 'api/customerFlow'
import { routes } from 'utils/routes'
import { useState } from 'react'
import { useRouter } from 'next/router'
import parse from 'html-react-parser'
import ShareButtons from 'features/referral/components/ShareButtons/ShareButtons'
import { useStoreSelector } from 'hooks/store'
import { ReferralFormInputs, ReferralFormProps } from 'features/referral/components/ReferralForm/types'
import { stringify } from 'qs'
import { getBoltWebsiteOrigin } from 'utils/helpers'

const ReferralForm = ({ activeAction }: ReferralFormProps) => {
  // Redux
  const { sfUserAlias } = useStoreSelector((store) => store.marketing)

  // Local state
  const [referralLink, setReferralLink] = useState<string>('')
  const [noEmailFound, setNoEmailFound] = useState<boolean>(false)
  const [internalError, setInternalError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [linkCopied, setLinkCopied] = useState<boolean>(false)

  // I18N
  const { t } = useTranslation(['referral', 'common', 'commonFormFields', 'validation'])

  // Router
  const router = useRouter()
  const { locale: language } = router

  // React Form
  const {
    reset: formReset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ReferralFormInputs>()

  /**
   * Checks is the email is from a bolt client
   * and sets the referralLink created for that user
   *
   * @param {ReferralFormInputs} data
   */
  const onSubmit = async (data: ReferralFormInputs) => {
    setLoading(true)

    try {
      const code = await getReferralCodeByEmail(data.email, sfUserAlias)

      if (code) {
        const searchQuery = stringify({ referralcode: code, sfu: sfUserAlias }, { addQueryPrefix: true, skipNulls: true })
        setReferralLink(`${getBoltWebsiteOrigin()}${routes(language).referral}${searchQuery}`)
      } else {
        setNoEmailFound(true)
      }
    } catch (error) {
      setInternalError(true)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Resets the form to its initial state
   */
  const handleReset = () => {
    formReset()
    setInternalError(false)
    setNoEmailFound(false)
    setLoading(false)
  }

  /**
   * Copies the referralLink to the users clipboard
   */
  const copyToClipboard = () => {
    setLinkCopied(true)

    if (navigator.clipboard) {
      void navigator.clipboard.writeText(referralLink)
      return
    }

    const textArea = document.createElement('textarea')
    textArea.value = referralLink
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }

  if (internalError || noEmailFound) {
    return (
      <div data-scrolltarget="" tabIndex={-1} className={styles.errorContainer}>
        {internalError && <p className={classNames(styles.internalError, 'card')}>{parse(t('common:error'))}</p>}
        {noEmailFound && <p className={classNames(styles.internalError, 'is-red')}>{parse(t('referral.form.errorNoEmailFound'))}</p>}
        <Button onClick={handleReset} className={styles.internalErrorButton}>
          {t('referral.form.buttonTryAgain')}
        </Button>
      </div>
    )
  }

  // referralLink
  if (referralLink) {
    return (
      <div className={classNames(styles.form, styles.referralLinkContainer)}>
        <div className={styles.formField}>
          <label htmlFor="link">{t('referral.form.linkLabel')}</label>
          <div className={styles.referralLinkLayout}>
            <input data-scrolltarget="" id="link" type="text" className="border" readOnly={true} value={referralLink} />
            <Button onClick={copyToClipboard} className={styles.submitButton}>
              {linkCopied ? t('referral.form.copied', 'Gekopieerd!') : t('referral.form.copy', 'Kopiëren')}
            </Button>
          </div>
        </div>
        <div className={styles.linkCardShare}>
          <strong className={styles.share}>{t('referral.form.share')}</strong>
          <ShareButtons {...{ activeAction, referralLink }} />
        </div>
      </div>
    )
  }

  // Email Verification
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.formField}>
        <label htmlFor="email">{t('common:yourEmail')}</label>
        <input
          data-scrolltarget=""
          {...register('email', { required: true, pattern: Email })}
          id="email"
          type="email"
          required
          className="border"
          placeholder={t('common:yourEmail')}
        />
        {errors.email && (
          <p className={classNames(styles.errorMsg, 'is-red')}>
            {errors.email.type === 'required' && t('validation:required', 'Dit veld is verplicht.')}
            {errors.email.type === 'pattern' && t('validation:email', 'Gelieve een geldig e-mailadres in te vullen.')}
          </p>
        )}
      </div>
      <Button className={styles.submitButton} loading={loading}>
        {t('referral.form.generate')}
      </Button>
    </form>
  )
}

export default ReferralForm
