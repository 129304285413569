import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { DEFAULT_NAMESPACES } from 'constants/i18n'
import { stringify } from 'qs'
import { getFaqQuestions } from 'api/cms/faq'
import { ReferralProps } from 'features/referral/types'
import { faqCategoriesSlugs } from 'constants/faq-categories'
import { FAQCategories } from 'features/faq-page/types'
import { checkPromoReferralCode } from 'api/customerFlow'
import { routes } from 'utils/routes'
import { COMMUNITY_SALES_DEFAULT_REDUCTION } from 'features/referral/constants'
import React from 'react'
import Layout from 'components/layout/Layout'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Referee from 'features/referral/views/Referee/Referee'
import Referral from 'features/referral/views/Referral/Referral'
import { getActiveReferralAction } from 'api/cms/referral-actions'

const ReferralPage = ({ faqQuestions, referralCode, activeAction }: ReferralProps) => {
  // React router
  const { locale } = useRouter()

  // i18n
  const { t } = useTranslation('referral')

  // Constants
  const amount = activeAction?.attributes?.reduction || COMMUNITY_SALES_DEFAULT_REDUCTION
  const firstName = referralCode?.referrer.firstName ?? ''

  return (
    <Layout
      seo={{
        canonical: `https://boltenergie.be/${routes(locale).referral}`,
        description: referralCode
          ? activeAction?.attributes?.shareLinkSeo?.metaDescription || t('referee.seo.paragraph', { firstName, amount })
          : activeAction?.attributes?.seo?.metaDescription || t('referral.seo.paragraph', { firstName, amount }),
        image:
          (referralCode
            ? activeAction?.attributes?.shareLinkSeo?.metaImage?.data?.attributes?.url
            : activeAction?.attributes?.image?.data?.attributes?.url) ||
          activeAction?.attributes?.image?.data?.attributes?.url ||
          `/images/seo/referral/${COMMUNITY_SALES_DEFAULT_REDUCTION.toString() + '-referral'}-${locale}.jpg`,
        title: referralCode
          ? activeAction?.attributes?.shareLinkSeo?.metaTitle || t('referee.seo.title', { firstName, amount })
          : activeAction?.attributes?.seo?.metaTitle || t('referral.seo.title', { firstName, amount })
      }}
    >
      {referralCode ? <Referee {...{ activeAction, faqQuestions, referralCode }} /> : <Referral {...{ activeAction, faqQuestions }} />}
    </Layout>
  )
}

export const getServerSideProps = async ({ locale, query }) => {
  // Get the FAQ questions & referral code
  const [faqQuestions, referralCodeResponse] = await Promise.all([
    getFaqQuestions(
      stringify({
        filters: {
          categories: {
            slug: {
              $in: faqCategoriesSlugs[FAQCategories.REFERRAL][locale]
            }
          }
        },
        locale
      })
    ),
    query.referralcode ? checkPromoReferralCode(query.referralcode as string) : undefined
  ])

  const referralCode = referralCodeResponse?.valid && referralCodeResponse?.referralCode ? referralCodeResponse.referralCode : null

  const activeAction = await getActiveReferralAction(locale, referralCode?.referrer?.firstName || '')

  return {
    props: {
      faqQuestions,
      activeAction: activeAction || null,
      referralCode,
      ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES, 'referral']))
    }
  }
}

export default ReferralPage
